<script>
export default {
  name: 'JobsTour',
  data() {
    return {
      jobsTourSteps: [
        {
          target: window.innerWidth > 1199 ? '.vs-sidebar-item-active' : '.navbar-custom',
          header: {
            title: '<strong>This is the Jobs Tab</strong>!',
          },
          content: `Here you can create and edit your active jobs. You will only be able to view Guide Profiles once you have an active job post.`,
          params: {
            enableScrolling: false,
            placement: 'auto',
          },
        },
        {
          target: this.$store.getters['jobsModule/getJobList'].length > 0 ? '#job-img' : '#post-job',
          header: {
            title:
              this.$store.getters['jobsModule/getJobList'].length > 0
                ? `<strong>You've created your first Job Post</strong>!`
                : '<strong>Create your first Job Post</strong>!',
          },
          content:
            this.$store.getters['jobsModule/getJobList'].length > 0
              ? `Visit the Guide Board to invite guides to apply.`
              : `Once you have created your first Job Post, visit the Guide Board to invite guides to apply.`,
          params: {
            enableScrolling: false,
            placement: 'auto',
          },
        },
      ],
      tourCallbacks: {
        onSkip: this.markTourAsSeen,
        onFinish: this.markTourAsSeen,
      },
    };
  },

  mounted() {
    const employer = this.$store.getters['employerModule/getUser'];
    if (!employer.dashboardTours || !employer.dashboardTours.jobsTourSeen) {
      setTimeout(() => {
        try {
          this.$tours['jobsTour'].start();
        } catch (error) {
          return;
        }
      }, 1000);
    }
  },
  methods: {
    markTourAsSeen() {
      this.$store.dispatch('employerModule/patch', { dashboardTours: { jobsTourSeen: true } });
    },
  },
};
</script>
<template>
  <v-tour name="jobsTour" :steps="jobsTourSteps" :callbacks="tourCallbacks"></v-tour>
</template>

<style lang="scss">
.v-step,
.v-step__header,
.v-step__arrow,
.v-step__arrow::before {
  background: #adca63 !important;
}
.v-step {
  border-radius: 10px !important;
  z-index: 99999 !important;
}
.v-step__header {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  margin-top: 0 !important;
}

.v-step__button {
  border-radius: 5px !important;
  border-top-width: 0.1rem !important;
  font-size: 1rem !important;
}
</style>
